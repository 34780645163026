import React, { Component } from 'react'
import Lead from '../../../components/Lead'
import { SRLWrapper } from 'simple-react-lightbox'

import imageA from './a.jpg'
import imageB from './b.png'
import imageC from './c.png'
import imageD from './d.png'

const options = {
  overlayColor: 'rgba(0, 0, 0, 0.9)',
  transitionTimingFunction: 'ease',
  slideTransitionSpeed: 1000,
  buttonsIconPadding: '2px',
  buttonsIconColor: 'rgba(255, 255, 255, 0.5)',
  enablePanzoom: false,
  hideControlsAfter: 0
}

export default class Canine49 extends Component {
  render() {
    return (
      <div>
        <div>
          <Lead>
            Canine49 is a narratively driven, point-and-click adventure game
            about a boy and his mechanical dog. The project was made at the end
            of our second year as a request for the Rainbow Foundation, a wish
            granting organisation located in Michigan, US. Canine49 was made in
            a team of 16 people.
          </Lead>

          {/* Column Left */}

          <div className="row">
            <div className="col">
              <h1>The Project</h1>
              <div>
                <h2>
                  <ul>
                    <li>Released on Itch.io</li>
                    <li>2nd year student project </li>
                    <li>8 weeks of development</li>
                    <li>16 people </li>
                    <li>Made in Unreal Engine 4</li>
                  </ul>
                </h2>
                <h2>
                  Canine49 saw me work on Puzzles and the overall narrative
                  structure of the game. 4 puzzles were developed for the game,
                  with 2 having found their way into the final product.
                </h2>
              </div>
              <p>
                More information and the playable version of the game can be
                found on <a href="https://igad.itch.io/canine-49">Itch.io</a>
              </p>
            </div>

            {/* Column Right */}

            <div className="col">
              <h1>My roles</h1>
              <h2>
                <ul>
                  <li>Puzzle Designer</li>
                  <li>Narrative Designer</li>
                </ul>
              </h2>

              <p>
                <iframe
                  title="Canine49 YouTube Trailer"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/R__fqom2uMo"
                  frameBorder="1"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </p>
              <p>Trailer for Canine49 on YouTube</p>
            </div>
          </div>

          {/* Screenshots */}

          <SRLWrapper options={options}>
            <div className="row no-gutters">
              <div className="col-6">
                <a href="#">
                  <img src={imageA} className="img-fluid" alt="" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageB} className="img-fluid" alt="" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageC} className="img-fluid" alt="" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageD} className="img-fluid" />
                </a>
              </div>
            </div>
          </SRLWrapper>
        </div>
      </div>
    )
  }
}
