import React, { Component } from "react";
import Lead from "../../../components/Lead";
import { SRLWrapper } from "simple-react-lightbox";

import imageA from "./PF_A.jpg";
import imageB from "./PF_B.png";
import imageC from "./PF_C.png";
import imageD from "./PF_D.png";
import imageE from "./PF_E.png";

const options = {
  overlayColor: "rgba(0, 0, 0, 0.9)",
  transitionTimingFunction: "ease",
  slideTransitionSpeed: 1000,
  buttonsIconPadding: "2px",
  buttonsIconColor: "rgba(255, 255, 255, 0.5)",
  enablePanzoom: false,
  hideControlsAfter: 0,
};

export default class Planetfall extends Component {
  render() {
    return (
      <div>
        <Lead>
          "Age of Wonders: Planetfall is the new strategy game from Triumph
          Studios, creators of the critically acclaimed Age of Wonders-series,
          bringing all the exciting strategic turn-based combat and in-depth
          empire building of its predecessors to an all-new sci-fi setting."
        </Lead>

        {/* Column Left */}

        <div className="row">
          <div className="col">
            <h1>The Project</h1>
            <div>
              <h2>
                <ul>
                  <li>Released on PC, Mac, Xbox, PS4</li>
                  <li>4th year Internship </li>
                  <li>Made in Triumph's In-House Engine</li>
                </ul>
              </h2>
              <h2>
                <i>
                  I joined Triumph in November 2019 as part of my internship and
                  started working on the Invasions DLC just after the concepting
                  phase was over. <br></br>
                  <br></br>
                  From November until late February I was responsible for the
                  content design in the World Event feature: A minor feature
                  brought back from the previous game, but given a unique
                  flavour. For this I did almost all of the mechanical and
                  thematic design, implementation, and writing. <br></br>
                  <br></br>
                  March 2020 onwards we started work on the 3rd expansion, where
                  my primary responsibility is the design of an all new system
                  unique to planetfall. Secondarily I am helping to support my
                  colleagues on all different parts of the next expansion's
                  design.
                </i>
              </h2>
              <p>
                The biggest take away from this internship is learning to work
                professionally with experienced colleagues. Delivering high
                quality content, being present from start to finish on a
                project, learning all the ins and outs of what it's like to
                actually work on a game - instead of doing the school work for a
                grade.
              </p>
            </div>
            <p>
              More information and the playable version of the game can be found
              on{" "}
              <a href="https://www.aow-planetfall.com/">the official website</a>
            </p>
          </div>

          {/* Column Right */}

          <div className="col">
            <h1>My roles</h1>
            <h2>
              <ul>
                <li>System & Content Design</li>
                <li>Playtesting & Balance</li>
                <li>Bugfixing</li>
              </ul>
            </h2>

            <p>
              <i>DLC 3 has yet to be released, and as such no work can be shown yet.</i>
            </p>


            <p>
              <iframe
                title="Kra-Ken YouTube"
                width="550"
                height="310"
                src="https://www.youtube.com/embed/cJRB1EvIG4A"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </p>
            <p>Age of Wonders: Planetfall INVASIONS - Announcement Trailer</p>
          </div>
        </div>

        {/* Screenshots */}
        <div className="row">
          <SRLWrapper options={options}>
            <div className="row no-gutters">
              <div className="col-6">
                <a href="#">
                  <img src={imageE} className="img-fluid" alt="" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageD} className="img-fluid" alt="" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageA} className="img-fluid" alt="" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageB} className="img-fluid" alt="" />
                </a>
              </div>
            </div>
          </SRLWrapper>
        </div>
      </div>
    );
  }
}
