import React, { Component } from 'react'
import Lead from '../../../components/Lead'
import { SRLWrapper } from 'simple-react-lightbox'

import imageA from './DND_A.png'
import imageB from './DND_B.jpg'
import imageC from './DND_C.png'
import imageD from './DND_D.jpg'
import imageE from './DND_E.jpg'
import imageF from './DND_F.jpg'

const options = {
  overlayColor: 'rgba(0, 0, 0, 0.9)',
  transitionTimingFunction: 'ease',
  slideTransitionSpeed: 1000,
  buttonsIconPadding: '2px',
  buttonsIconColor: 'rgba(255, 255, 255, 0.5)',
  enablePanzoom: false,
  hideControlsAfter: 0
}

export default class DungeonsAndDragons extends Component {
  render() {
    return (
      <div>
        <div>
        <Lead>
        Since 2011 I have played Dungeons and Dragons, and during those 9 years I have focused most of my time on being the Dungeon Master.
      8 or so campaigns, many players, and even more sessions as a Dungeon Master have taught me about everything from storytelling and pacing to systems design and player progression.
        </Lead>

        {/* Column Left */}

        <div className="row">
          <div className="col">
            <p>
              <h1>The Statistics</h1>
            </p>
            <div>
              <h2><ul>
                <li>8 Campaigns</li>
                <li>30+ players</li>
                <li>100+ sessions</li>
                <li>5 settings </li>
                <li>10+ adventures</li>
                <li>Many, many characters</li>
              </ul></h2>
                <h2>
                Dungeons and Dragons combines a lot of things I love: Playing games with friends, storytelling, nearly all kinds of game design, and socializing. 
                Over the years I have grown to be a better Dungeon Master and player by learning to give everyone the room they need and moment to shine. D&D is about having fun,
                not about winning the game. 
              </h2>
            </div>
            <p>
            A small part of my setting can be found here on{' '}
            <a href="https://www.worldanvil.com/w/vesmir-amanatil">
              WorldAnvil.
            </a>
           </p>
          </div>

          {/* Column Right */}

          <div className="col">
           <h1></h1>
              <p>               
              </p>
          </div>
        </div>

        {/* Screenshots */}

        <SRLWrapper options={options}>
            <div className="row no-gutters">
              <div className="col-6">
                <a href="#">
                  <img src={imageC} className="img-fluid" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageD} className="img-fluid" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageE} className="img-fluid" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageF} className="img-fluid" />
                </a>
              </div>
            </div>
          </SRLWrapper>

        </div>
      

      </div>



    )
  }
}
