import React, { Component } from 'react'
import HoverImage from '../components/HoverImage'
import Layout from '../components/Layout'
import Column from '../components/Column'
import Centered from '../components/Centered'
import Row from '../components/Row'
import GameDesignData from '../data/GameDesignData'

class GameDesign extends Component {
  componentWillMount() {
    this.setState({ projects: GameDesignData })
  }

  render() {
    const { projects } = this.state
    return (
      <Layout type="overview game-design">
        <Row>
          {projects &&
            projects.map((project, index) => (
              <Column className={index === 0 ? 'col-sm-12' : 'col-sm-6'}>
                <HoverImage
                  mode="light"
                  image={project.image}
                  url={project.slug && `/game-design/${project.slug}`}
                >
                  <div className="container text-center">
                    <Row>
                      <Column>
                        <Centered>
                          <h1>{project.title}</h1>
                        </Centered>
                      </Column>
                    </Row>

                    <Row>
                      <Column>
                        <Centered>
                          <h2>{project.subtitle}</h2>
                        </Centered>
                      </Column>
                    </Row>

                    <Row>
                      {project.role && (
                        <Column>
                          <h3>Role: {project.role}</h3>
                        </Column>
                      )}
                      {project.release && (
                        <Column>
                          <h3>{project.release}</h3>
                        </Column>
                      )}
                      {project.engine && (
                        <Column>
                          <h3>Engine: {project.engine}</h3>
                        </Column>
                      )}
                    </Row>
                  </div>
                </HoverImage>
              </Column>
            ))}
        </Row>
      </Layout>
    )
  }
}

export default GameDesign
