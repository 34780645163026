import React, { Component } from 'react'
import Lead from '../../../components/Lead'
import { SRLWrapper } from 'simple-react-lightbox'

import imageA from './PBH_A.png'
import imageB from './PBH_B.png'
import imageC from './PBH_C.png'

const options = {
  overlayColor: 'rgba(0, 0, 0, 0.9)',
  transitionTimingFunction: 'ease',
  slideTransitionSpeed: 1000,
  buttonsIconPadding: '2px',
  buttonsIconColor: 'rgba(255, 255, 255, 0.5)',
  enablePanzoom: false,
  hideControlsAfter: 0
}

export default class ProjectBlackHole extends Component {
  render() {
    return (
      <div>
        <Lead>
        Project Black Hole is a First Person shooter and homage to the 2016
          iteration of DOOM. Made in our second year, with a team of 4
          designers. The whole game is made in Unreal Engine 4 (version 4.18),
          and all of the logic is made in Unreal Blueprints. Made in Spring
          2018.
        </Lead>

        {/* Column Left */}

        <div className="row">
          <div className="col">
            <p>
              <h1>The Project</h1>
            </p>
            <div>
              <h2><ul>
                <li>3 Weapons, 6 firing modes</li>
                <li>3 Levels </li>
                <li>3 Enemy types</li>
                <li>10+ custom particle effects </li>
              </ul></h2>
              <h2>
              The challenge for this project was to make a game that led the player from onboarding to mastery in what was little more than a prototype.<br></br>
             My responsibilities:
              <ul>
                <li>Combat design & logic</li>
                <li>AI design & logic</li>
                <li>Particle Effects</li>
              </ul>

              </h2>
            </div>
            <p>
            
           </p>
          </div>

          {/* Column Right */}

          <div className="col">
           <h1>My roles</h1>
           <h2><ul>
              <li>Technical Designer</li>
              <li>Combat Designer</li>
            </ul></h2>
              
              <p>               
                <iframe
                title="Kra-Ken YouTube"
                width="550"
                height="310"
                src="https://www.youtube.com/embed/YXJq-vqhaw0"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                ></iframe>
                </p>
          </div>
        </div>
        {/* Screenshots */}

        <SRLWrapper options={options}>
            <div className="row no-gutters">
              <div className="col-6">
                <a href="#">
                  <img src={imageA} className="img-fluid" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageB} className="img-fluid" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageC} className="img-fluid" />
                </a>
              </div>
            </div>
          </SRLWrapper>
      </div>
    )
  }
}
