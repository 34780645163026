import React, { Component } from 'react'
import HoverImage from '../components/HoverImage'
import Layout from '../components/Layout'
import Column from '../components/Column'
import Row from '../components/Row'
import Caption from '../components/Caption'
import photography from '../data/photography.json'

class Photography extends Component {
  componentWillMount() {
    this.setState({ photography: photography })
  }

  render() {
    const { photography } = this.state
    return (
      <Layout type="overview photography">
        <Row>
          {photography &&
            photography.map(project => (
              <Column className="col-sm-4">
                <HoverImage
                  mode="light"
                  image={project.image}
                  url={project.url}
                >
                  <Caption>
                    <h1>{project.title}</h1>
                    <p>{project.subtitle}</p>
                  </Caption>
                </HoverImage>
              </Column>
            ))}
        </Row>
      </Layout>
    )
  }
}

export default Photography
