import React from 'react'
import HoverImage from '../components/HoverImage'
import Layout from '../components/Layout'
import Column from '../components/Column'
import Centered from '../components/Centered'
import Row from '../components/Row'

const HomePage = () => (
  <Layout type="overview HomePage">
    <Row>
      {/* Top Left */}
      <Column>
        <HoverImage
          mode="light"
          image="https://i.imgur.com/0b33iEt.jpg"
          url="/about"
        >
         <div className="container text-center">
            <Row>
              <Column>
                <Centered>
                  <h1>Nathan van den Berg</h1>
                </Centered>
              </Column>
            </Row>
              <Row>
                <Column>
                  <Centered>
                    <h2>Content Design Intern at Triumph Studios</h2>
                  </Centered>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Centered>
                    <h3> Looking for a job in Game Design starting September 2020 </h3>
                  </Centered>
                </Column>
              </Row>
              <Row>
                <Centered>
                  <h2>Click here for more information</h2>
                </Centered>
              </Row>

            </div>
        </HoverImage>
      </Column>
    </Row>
    <Row>
      <Column>
        <HoverImage
          mode="light"
          image="https://i.imgur.com/7AeReas.jpg"
          url="/game-design"
        >
         
         <div className="container text-center">
            <Row>
              <Column>
                <Centered>
                  <h1>Project List</h1>
                </Centered>
              </Column>
            </Row>

            <Row>
              <Column>
                <Centered>
                  <h3>Over the course of my education at BUAS I have made a number of different games and worked on a host of different projects. Most of those are listed here.</h3>
                </Centered>
              </Column>
            </Row>
            <Row >
                      <Column>
                        <h2>My Roles:</h2> <p>Tech Designer, Level Designer, Producer & QA</p>
                      </Column>
                      <Column>
                        <h2>2 Released projects</h2><p>10 unreleased projects</p>
                      </Column>
                      <Column>
                        <h2>Tools & Software</h2> <p>Unreal Engine 4, Perforce, Photoshop</p>
                      </Column>
                    </Row>
          
            <Row>
              <Centered>
                <h2>Click here for more information</h2>
              </Centered>
            </Row>

            </div>
        </HoverImage>
      </Column>
    </Row>
    

    <Row>
{/* Top right */}
      <Column>
        <HoverImage 
          mode="light"
          image="https://i.imgur.com/p8H0e16.png" 
          url="/game-design/kra-ken"
          >

          <div className="container text-center">
          <Row>
              <Column>
                <Centered>
                  <h1>Project: Kra-Ken</h1>
                </Centered>
              </Column>
            </Row>

            <Row>
              <Centered>
                <h3>Kra-Ken is a 2.5d fighting game, an homage to Street Fighter. The game was made in 40 weeks, with 20 to 30 people.</h3>
              </Centered>
            </Row>
            <Row >
                      <Column>
                        <h2>My roles: Design & QA</h2>
                      </Column>
                      <Column>
                        <h2>Released on Steam</h2><p>With 100% positive reviews</p>
                      </Column>
                      <Column>
                        <h2>Made in Unreal Engine 4</h2>
                      </Column>
                    </Row>
            <Row>
              <Centered>
                <h2>Click here for more information</h2>
              </Centered>
            </Row>
            </div>
        </HoverImage>
      </Column>
      {/* Bottom Left  */}
      </Row>
      <Row>

      {/* Bottom Right  */}
      <Column>
        <HoverImage
         mode="light"
         image="https://i.imgur.com/Auvm05C.png"
         url="/game-design/canine49"
         >
           
           <div className="container text-center">
            <Row>
              <Column>
                <Centered>
                  <h1>Project: Canine49</h1>
                </Centered>
              </Column>
            </Row>

            <Row>
              <Column>
                <Centered>
                  <h3>Canine49 is a narratively driven, point-and-click adventure game about a boy and his mechanical dog. Fully hand-drawn visuals, interesting puzzles, and a compelling narrative come together to bring a unique experience.</h3>
                </Centered>
              </Column>
            </Row>
            <Row >
                      <Column>
                        <h2>My roles: Design & QA</h2>
                      </Column>
                      <Column>
                        <h2>Released on Itch.io</h2>
                      </Column>
                      <Column>
                        <h2>Made in Unreal Engine 4</h2>
                      </Column>
                    </Row>
            <Row>
              <Centered>
                <h2>Click here for more information</h2>
              </Centered>
            </Row>

            </div>
        </HoverImage>
      </Column>
    </Row>
    
    <Row>
    <Column>
        <HoverImage
          mode="light"
          image="https://i.imgur.com/FB10zop.jpg"
          url="/photography"
        >
         
         <div className="container text-center">
            <Row>
              <Column>
                <Centered>
                  <h1>Photography</h1>
                </Centered>
              </Column>
            </Row>

            <Row>
              <Column>
                <Centered>
                  <h3>Photography is my way to express myself visually. Using mobile lights and colour swatches while shooting photos of cosplayers I'm able to convey my own creative vision and twist on well-known characters.</h3>
                </Centered>
              </Column>
            </Row>
            <Row >
                      <Column>
                        <h2>Tools of the trade:</h2> <p>Canon 70D & 3 Yongnuo Speedlite 560</p>
                      </Column>
                      <Column>
                        <h2>Worked with over 50 Models</h2>
                      </Column>
                      <Column>
                        <h2>Editing in Adobe Lightroom & Photoshop</h2>
                      </Column>
                    </Row>
            <Row>
              <Centered>
                <h2>Click here for more information</h2>
              </Centered>
            </Row>

            </div>
        </HoverImage>
      </Column>
      </Row>
     
  </Layout>
)

export default HomePage
