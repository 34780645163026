import React from 'react'

const Header = () => (
  <header className="dark">
    <div className="container-fluid">
      <a href="/" className="title">
        Nathan van den Berg | Game Designer
      </a>
      <ul>
        <li>
          <a href="/about">About</a>
        </li>
        <li>
          <a href="/game-design"> Game Projects</a>
        </li>
        <li>
          <a href="/photography"> Photos </a>
        </li>
      </ul>
    </div>
  </header>
)

export default Header
