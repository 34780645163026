import React from 'react'
import Planetfall from '../pages/game-design/Planetfall/Planetfall'
import KraKen from '../pages/game-design/KraKen/KraKen'
import Canine49 from '../pages/game-design/Canine49/Canine49'
import ProjectBlackHole from '../pages/game-design/ProjectBlackHole/ProjectBlackHole'
import UnrealTournament from '../pages/game-design/UnrealTournament/UnrealTournament'
import DungeonsAndDragons from '../pages/game-design/DungeonsAndDragons/DungeonsAndDragons'

const GameDesignData = [
  {
    title: 'Age of Wonders: Planetfall',
    subtitle:
      'Age of Wonders: Planetfall is a Sci-Fi 4x Grand Stategy game by Triumph Studios',
    role: 'System & Content Design',
    engine: 'In-House',
    release: 'Released',
    click: 'Click here for more information',
    slug: 'planetfall',
    image: 'https://i.imgur.com/1PdpVH6.jpg',
    component: <Planetfall />
  },
  {
    title: 'Kra-Ken',
    subtitle:
      'Kra-Ken is a 2.5 Fighting Game, and homage to the Street Fighter series.',
    role: 'Designer & QA',
    engine: 'Unreal Engine 4',
    release: 'Released on Steam',
    click: 'Click here for more information',
    slug: 'kra-ken',
    image: 'https://i.imgur.com/SIYq8Sp.png',
    component: <KraKen />
  },
  {
    title: 'Canine49',
    subtitle:
      'A hand-drawn point-and-click adventure game about a boy and his robot dog.',
    role: 'Puzzle and Narrative Designer',
    engine: 'Unreal Engine 4',
    release: 'Released on itch.io',
    click: 'Click here for more information',
    slug: 'canine49',
    image: 'https://i.imgur.com/zeTjjj4.png',
    component: <Canine49 />
  },
  {
    title: 'Project Black Hole',
    subtitle:
      'Project Black Hole is a First Person shooter and homage to the 2016 iteration of DOOM',
    role: 'Technical Designer',
    engine: 'Unreal Engine 4',
    release: 'Unreleased',
    click: 'Click here for more information',
    slug: 'pbh',
    image: 'https://i.imgur.com/BgJa3Qy.png',
    component: <ProjectBlackHole />
  },
  {
    title: 'Unreal Tournament',
    subtitle:
      'A multiplayer, free-for-all deathmatch map made for Unreal Tournament 4.',
    role: 'Level Designer',
    engine: 'Unreal Engine 4',
    release: 'Unreleased',
    slug: 'utmap',
    image: 'https://i.imgur.com/7sXZysd.png',
    component: <UnrealTournament />
  },
  {
    title: 'Dungeons & Dragons',
    subtitle:
      'Creating compelling player interactions is one of the most enjoyable things in D&D for me. I write my own settings, create my own game-systems where necessary, and use a variety of tools to create and generate visuals I need for my campaigns.',
    role: 'DM for several campaigns',
    engine: '',
    release: '',
    click: 'Click here for more information',
    slug: 'dnd',
    image: 'https://i.imgur.com/QuDXt6a.png',
    component: <DungeonsAndDragons />
  },

  {
    title: 'Candy Chaos',
    subtitle:
      'Our final project in Year 1, voted the best looking game of year 1.',
    role: 'Level & Particle Designer',
    engine: 'Unreal Engine 4',
    release: 'Unreleased',
    slug: '',
    image: 'https://i.imgur.com/y6s0uXY.png'
  },
  {
    title: 'Game Marmalades',
    subtitle:
      'Based on the idea of Game-Jams, these small projects forced rapid prototyping and quick iteration.',
    role: 'Technical Designer',
    engine: 'Unreal Engine 4 & Unity',
    release: 'Unreleased',
    slug: '',
    image: 'https://i.imgur.com/9q7noBt.jpg'
  },
  {
    title: '2.5D Platformer',
    subtitle:
      'A 1st year excercise in teamwork, level design, and visual scripting.',
    role: 'Level & Technical designer',
    engine: 'Unreal Engine 4',
    release: 'Unreleased',
    slug: '',
    image: 'https://i.imgur.com/jFVqM6O.png'
  }
]

export default GameDesignData
