import React from 'react'
import HoverImage from '../components/HoverImage'
import Layout from '../components/Layout'
import Column from '../components/Column'
import Centered from '../components/Centered'
import Row from '../components/Row'

const HomePage = () => (
  <Layout type="overview game-design">
<Row>

{/* Top Left */}
<Column>
 <HoverImage
      mode="light"
      image="https://i.imgur.com/i3XphYA.jpg"
      url="/about"
    >
     <div className="container text-center">
        <Row>
          <Column>
            <Centered>
              <h1>Nathan van den Berg</h1>
            </Centered>
          </Column>
        </Row>

          <Row>
            <Column>
              <Centered>
                <h3>Game Designer & Producer, Photograher, and overall creative</h3>
              </Centered>
            </Column>
            <Column>
              <Centered>
                <h3>4th year student at Breda University of Applied Sciences</h3>
              </Centered>
            </Column>
          </Row>

          <Row>
            <Centered>
              <h2>Click here for more information</h2>
            </Centered>
          </Row>

        </div>
    </HoverImage>
  </Column>

{/* Top right */}
  <Column>
    <HoverImage 
      mode="light"
      image="https://i.imgur.com/p8H0e16.png" 
      url="/game-design/kra-ken"
      >

      <div className="container text-center">
      <Row>
          <Column>
            <Centered>
              <h1>Project: Kra-Ken</h1>
            </Centered>
          </Column>
        </Row>

        <Row>
          <Centered>
            <h3>Kra-Ken is a 2.5d fighting game, an homage to Street Fighter, and our third year project. During development I fulfilled roles from desginer to producer, to QA.</h3>
          </Centered>
        </Row>

        <Row>
          <Centered>
            <h2>Click here for more information</h2>
          </Centered>
        </Row>
       
       

        </div>
    </HoverImage>
  </Column>
</Row>

<Row>

  {/* Bottom Left  */}
  <Column>
    <HoverImage
      mode="light"
      image="https://i.imgur.com/FB10zop.jpg"
      url="/photography"
    >
     
     <div className="container text-center">
        <Row>
          <Column>
            <Centered>
              <h1>Project: Photography</h1>
            </Centered>
          </Column>
        </Row>

        <Row>
          <Column>
            <Centered>
              <h3>Photography is my way to express myself visually. Using mobile lights and colour swatches while shooting photos of cosplayers I'm able to convey my own creative vision and twist on well-known characters.</h3>
            </Centered>
          </Column>
        </Row>

        <Row>
          <Centered>
            <h2>Click here for more information</h2>
          </Centered>
        </Row>

        </div>
    </HoverImage>
  </Column>

  {/* Bottom Right  */}
  <Column>
    <HoverImage
     mode="light"
     image="https://i.imgur.com/Auvm05C.png"
     url="/game-design/canine49"
     >
       
       <div className="container text-center">
        <Row>
          <Column>
            <Centered>
              <h1>Project: Canine49</h1>
            </Centered>
          </Column>
        </Row>

        <Row>
          <Column>
            <Centered>
              <h3>Canine49 is a narratively driven, point-and-click adventure game about a boy and his mechanical dog. Fully hand-drawn visuals, interesting puzzles, and a compelling narrative come together to bring a unique experience.</h3>
            </Centered>
          </Column>
        </Row>

        <Row>
          <Centered>
            <h2>Click here for more information</h2>
          </Centered>
        </Row>

        </div>
    </HoverImage>
  </Column>
</Row>
</Layout>
)

export default HomePage
