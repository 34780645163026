import React, { Component } from 'react'
import Lead from '../../../components/Lead'
import { SRLWrapper } from 'simple-react-lightbox'

import imageA from './KRAKEN_A.png'
import imageB from './KRAKEN_B.png'
import imageC from './KRAKEN_C.png'
import imageD from './KRAKEN_D.png'
import imageE from './KRAKEN_E.png'
import imageF from './KRAKEN_F.png'

const options = {
  overlayColor: 'rgba(0, 0, 0, 0.9)',
  transitionTimingFunction: 'ease',
  slideTransitionSpeed: 1000,
  buttonsIconPadding: '2px',
  buttonsIconColor: 'rgba(255, 255, 255, 0.5)',
  enablePanzoom: false,
  hideControlsAfter: 0
}

export default class Kraken extends Component {
  render() {
    return (
      <div>
        <Lead>
          Kra-Ken is a 2.5d fighting game, an homage to Street Fighter. The game
          was made a our 3rd year project, taking one full year, or 40 work
          weeks. Kra-Ken was made in a team with between 20 and 30 people.
        </Lead>

        {/* Column Left */}

        <div className="row">
          <div className="col">
            <p>
              <h1>The Project</h1>
            </p>
            <div>
              <h2><ul>
                <li>Released on Steam</li>
                <li>3rd year student project </li>
                <li> 40 weeks of development</li>
                <li>20 to 30 people </li>
                <li>Made in Unreal Engine 4</li>
                <li>84% positive reviews on steam</li>
              </ul></h2>
              <h2><i>
                Being part of the team from start to finish I was able to take part in every aspect.
                Starting with research, design, and prototyping in the first half, and a switch to QA in the latter half. 
                We achieved a fix rate of 79% of all reported bugs, and were aware of all major bugs in the system at the end of development.
              </i></h2>
            </div>
            <p>
            More information and the playable version of the game can be found
            on{' '}
            <a href="https://store.steampowered.com/app/1105560/KraKen/?snr=1_7_7_151_150_1">
              Steam
            </a>
           </p>
          </div>

          {/* Column Right */}

          <div className="col">
           <h1>My roles</h1>
           <h2><ul>
              <li>QA & Testing</li>
              <li>Tech Design</li>
            </ul></h2>
              
              <p>               
                <iframe
                title="Kra-Ken YouTube"
                width="550"
                height="310"
                src="https://www.youtube.com/embed/VaPPc3DS1X0"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                ></iframe>
                </p><p>
                Gameplay video by PlayMaster on YouTube
              </p>
              
             
          </div>

        </div>

        {/* Screenshots */}
        <div className="row">
          <SRLWrapper options={options}>
            <div className="row no-gutters">
              <div className="col-6">
                <a href="#">
                  <img src={imageA} className="img-fluid" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageB} className="img-fluid" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageC} className="img-fluid" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageD} className="img-fluid" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageE} className="img-fluid" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageF} className="img-fluid" />
                </a>
              </div>
            </div>
          </SRLWrapper>

        </div>
        </div>
      
    )
  } 
}
