import React from 'react'
import ReactDOM from 'react-dom'

import { BrowserRouter, Route, Switch } from 'react-router-dom'

import './styling'

import * as serviceWorker from './serviceWorker'
import Header from './components/Header'
import HomePage from './pages/HomePage'
import HomePage2 from './pages/HomePage2'
import Photography from './pages/Photography'
import GameDesign from './pages/GameDesign'
import GameDesignDetail from './pages/GameDesignDetail'
import About from './pages/About'
import SimpleReactLightbox from 'simple-react-lightbox'


ReactDOM.render(
  <BrowserRouter>
    <SimpleReactLightbox>
      <Header />
      <Switch>
        <Route path="/about" component={About} />
        <Route path="/photography" component={Photography} />
        <Route path="/game-design/:slug" component={GameDesignDetail} />
        <Route path="/game-design" component={GameDesign} />
        <Route path="/homepage2" component={HomePage2} />
        <Route path="/" component={HomePage} />
      </Switch>
    </SimpleReactLightbox>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
