import React, { Component } from 'react'
import Image from '../components/Image'
import Layout from '../components/Layout'
import Column from '../components/Column'
import Centered from '../components/Centered'
import Row from '../components/Row'
import GameDesignData from '../data/GameDesignData'


class GameDesignDetail extends Component {
  componentWillMount() {
    const {
      match: {
        params: { slug }
      }
    } = this.props

    const project = GameDesignData.find(p => p.slug === slug)
    this.setState({ project })
  }
  render() {
    const { project } = this.state
    if (!project) {
      return <p>project not found..</p>
    }
    const { component, title, image } = project
    const GameDesignComponent = component;
    console.log(GameDesignComponent)
    return (
      <div className="game-detail">
        <Image height="450px" image={image}>
          <Centered>
            <h1>{title}</h1>
          </Centered>
        </Image>

        <Layout type="page">
          <Row>
            <Column>
              {component && component}
            </Column>
          </Row>
        </Layout>
      </div>
    )
  }
}

export default GameDesignDetail
