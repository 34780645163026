import React from 'react'
import Image from '../components/Image'
import Layout from '../components/Layout'
import Column from '../components/Column'
import Row from '../components/Row'

const About = () => (
  <Layout type="page">
  {/* <Image className="image-landscape" image="https://i.imgur.com/4ImafcEh.jpg" /> */}
  {/* <p>Photo by <u><a href="https://www.facebook.com/JeroenWeimarPhotography/">Jeroen Weimar</a></u></p> */}
  <Row>
    <Column>
    <a><img src="https://i.imgur.com/jLsXeZZ.jpg" width="500" height="750" alt=""/></a>
      
      <h1>Contact</h1>
      <Row>
      <Column>
      <h2><b>For more information</b></h2>
      <h3>info@nathanvandenberg.nl</h3>
      </Column><Column>
      <h2><b>Profiles</b></h2>
      <h3> 
       <u><a href="https://www.linkedin.com/in/nathan-van-den-berg-9616b7186">LinkedIn</a></u> | &nbsp;
       <u><a href="https://steamcommunity.com/profiles/76561198004994485/">Steam</a></u>
       </h3>
       </Column>
       </Row>
    </Column>


    <Column>

    <h1><u>Nathan van den Berg</u></h1>
      <p><i>
        Games have always been a big part of my life, from watching my dad play Half-Life and playing Morrowind and Warcraft III as a kid, to 
        playing Dungeons & Dragons, MMO's, and 4X games with friends as an adult. 
        Creating all kinds of gaming experiences and getting to interact with people and systems alike is one of my greatest passions.
      </i></p>

    <h1>Education & Skills</h1>
    
      <h2><u>Breda University of Applied Sciences</u></h2>
      <h3>Game Design and Production | <i>September 2016 - August 2020</i></h3><b></b> 
    <p>
      <i>
        At BUAS I studied the Creative Media and Game Technologies (formerly known as IGAD) course and graduated in August 2020.
        After 3 years of project-based education in all aspects of game design and development I took a 40 week internship at Triumph Studios. 
      </i>
    
      <h2><u>Internship at Triumph Studios</u> </h2>
      <h3>Content & System Designer | <i>November 2019 - August 2020</i></h3>
      <i>At Triumph I worked on both the 2nd and 3rd DLC, where my responsbilities ranged from designing content on DLC 2, to designing a new gameplay systems
        on DLC 3. In this time I also took care of UI design, quest creation, content implementation, and writing of flavor text.
      </i>
    </p> 

        <p>
        <Row>
            <Column>
              <h2><b>Software Proficiencies</b></h2>
              <h3>
                <ul>
                  <li>Unreal Engine 4</li>
                  <li>Photoshop</li>
                  <li>Perforce</li>
                  <li>Git</li>
                  <li>Jira</li>
                  <li>Google Tools</li>
                  <li>Steam Developer Tools</li>
                </ul>
                </h3>
            </Column>
                
            <Column>
              <h2><b>Skills</b></h2>
              <h3>
              <ul>
                  <li>Visual Scripting</li>
                  <li>Particle Effects</li>
                  <li>(Rapid) Prototyping</li>
                  <li>Version Control</li>
                  <li>Level Design</li>
                  <li>System Design</li>
                  <li>Scrum</li>
                  <li>QA / Bug Testing</li>
                  <li>Community Management</li>
                  <li>Professional Writing</li>
                  <li>Steam Release</li>
              </ul>
              </h3>
            </Column>
         </Row>

        <Row>
          <Column>
            <h3>
              For a list of projects, please see the <u><a href= "https://nathanvandenberg.nl/game-design">Game Projects</a></u> Page
            </h3>
          </Column>
        </Row>

         <Row>
           <Column>
           <h2><b>Languages</b></h2>
           <ul>
             <li><b>Dutch</b></li>
             Native language. Fully fluent.

             <li><b>English</b></li>
             Fully fluent.

             <li><b>Google Translate</b></li>
             Mostly Fluent.
           </ul>
           </Column>
         </Row>
        </p>
    
    
    </Column>
  
  </Row>
    <Row>
      {/* <Column>
      <h1>Contact</h1>
      <h2><b>For more information & Bookings</b></h2>
      <h3>info@nathanvandenberg.nl</h3>
      <h2><b>Social Media</b></h2>
      <h3>Instagram | Facebook | LinkedIn</h3>
      </Column>
      <Column>
        <h1>About</h1>
        <p>A page with info about me</p>
       
      </Column> */}
    </Row>
  </Layout>
)

export default About
