import React from 'react'

const Image = ({ image, mode, height, children, className }) => (
  <div
    className={`image ${mode === 'light' ? 'light' : 'dark'} ${className}`}
    style={{ backgroundImage: `url(${image})`, height: height || 'auto' }}
  >
    {children}
  </div>
)

export default Image
