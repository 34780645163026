import React, { Component } from 'react'
import Lead from '../../../components/Lead'
import { SRLWrapper } from 'simple-react-lightbox'

import imageA from './UT_A.png'
import imageB from './UT_B.png'
import imageC from './UT_C.png'
import imageD from './UT_D.png'
import imageE from './UT_E.png'
import imageG from './UT_LD_A.jpg'
import imageH from './UT_LD_B.png'

const options = {
  overlayColor: 'rgba(0, 0, 0, 0.9)',
  transitionTimingFunction: 'ease',
  slideTransitionSpeed: 1000,
  buttonsIconPadding: '2px',
  buttonsIconColor: 'rgba(255, 255, 255, 0.5)',
  enablePanzoom: false,
  hideControlsAfter: 0
}

export default class UnrealTournament extends Component {
  render() {
    return (
      <div>
        <Lead>
        A fully playable and balanced Multiplayer map for Unreal Tournament
          with a limited set of weapons. Designed from scratch up to including a
          full lighting pass. 
        </Lead>

        {/* Column Left */}

        <div className="row">
          <div className="col">
            <p>
              <h1>The Project</h1>
            </p>
            <div>
              <h2><ul>
                <li>2 to 8 player map</li>
                <li>Made for Unreal Tournament</li>
                <li>8 week of development</li>
              </ul></h2>
              <h2><i>
              The level consists of 3 layers and a number of rooms around the central 2 rings. With multiple entries and exist to move around smoothly, this level allows players to freely pick a route
              while always forcing them together in the central chamber. 
              <br></br>Colours are used to distinguish each room, and the central rings have been given unique lighting to make them stand out as a landmark in the level.
              </i></h2>
            </div>
          </div>

          {/* Column Right */}

          <div className="col">
           <h1>My roles</h1>
           <h2><ul>
              <li>Level Designer</li>
            </ul></h2>
            <p>
            <a><img src="https://i.imgur.com/7sXZysd.png" width="750" height="421.88" /></a>
            </p>
              {/* <p>               
                <iframe
                title="Kra-Ken YouTube"
                width="550"
                height="310"
                src="https://www.youtube.com/embed/VaPPc3DS1X0"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                ></iframe>
                </p><p>
                VIDEO CREDIT
              </p> */}
              
             
          </div>

        </div>

        {/* Screenshots */}

        <SRLWrapper options={options}>
            <div className="row no-gutters">
              <div className="col-6">
                <a href="#">
                  <img src={imageG} className="img-fluid" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageH} className="img-fluid" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageC} className="img-fluid" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageD} className="img-fluid" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageE} className="img-fluid" />
                </a>
              </div>
              <div className="col-6">
                <a href="#">
                  <img src={imageB} className="img-fluid" />
                </a>
              </div>
            </div>
          </SRLWrapper>

      </div>
    )
  }
}
