import React, { Fragment } from 'react'

const HoverImage = ({ image, url, mode, children }) => {
  console.log(url)
  return (
    <Fragment>
      {url && (
        <a
          href={url || '#'}
          className={`hover-image ${mode === 'light' ? 'light' : 'dark'}`}
        >
          <div
            className="image-content"
            style={{ backgroundImage: `url(${image})` }}
          />
          <div className="content"> {children}</div>
          <div className="overlay" />
        </a>
      )}
      {!url && (
        <div className={`hover-image ${mode === 'light' ? 'light' : 'dark'}`}>
          <div
            className="image-content"
            style={{ backgroundImage: `url(${image})` }}
          />
          <div className="content"> {children}</div>
          <div className="overlay" />
        </div>
      )}
    </Fragment>
  )
}

export default HoverImage
